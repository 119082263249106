import { ValidationErrorBody } from '@cumu/shared';
import { displayServerValidationError } from './forms/validation';

export async function readExcelFile(
  arrayBuffer: Promise<ArrayBuffer>,
  name: string,
  form: HTMLFormElement
) {
  const worker = new Worker(new URL('./excel-worker.ts', import.meta.url), {
    type: 'module'
  });
  await new Promise<MessageEvent>(resolve =>
    worker.addEventListener('message', resolve, { once: true })
  );
  worker.postMessage(await arrayBuffer);
  const event = await new Promise<
    MessageEvent<
      { type: 'xlsx-error' } | { type: 'xlsx-data'; data: unknown[] }
    >
  >(resolve => worker.addEventListener('message', resolve, { once: true }));
  worker.terminate();
  if (event.data.type === 'xlsx-error') {
    const error: Omit<ValidationErrorBody, 'code'> = {
      type: 'validation-error',
      message: `Error opening "${name}". Ensure the file is a valid Excel file.`
    };
    displayServerValidationError(form, error);
    return null;
  }
  return event.data.data;
}
