import { getAge } from '../date';
import { ActivityResponseValue } from '../model/activity';
import { Property, PropertyType } from '../model/entity-type';
import { Expression } from '../model/expression';
import { Role } from '../model/organization';
import {
  AreaOfPersistentUnemploymentLookupItem,
  RucaLookupItem
} from '../model/person';
import { roundNumber } from '../number';
import { legacyEvaluate } from './legacy-evaluate';

export type BasicProperty = Pick<
  Property,
  'property_id' | 'type' | 'choices' | 'choice_type'
>;

export const standardProperties: Record<string, BasicProperty> = {
  ORIGINAL_ACTIVITY_STATUS: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  ORIGINAL_ACTIVITY_START_DATE: {
    property_id: 0,
    type: 'date',
    choice_type: 'none'
  },
  ORIGINAL_ACTIVITY_END_DATE: {
    property_id: 0,
    type: 'date',
    choice_type: 'none'
  },
  ACTIVITY_STATUS: { property_id: 0, type: 'varchar100', choice_type: 'none' },
  ACTIVITY_CREATE_DATE: { property_id: 0, type: 'date', choice_type: 'none' },
  ACTIVITY_START_DATE: { property_id: 0, type: 'date', choice_type: 'none' },
  ACTIVITY_END_DATE: { property_id: 0, type: 'date', choice_type: 'none' },
  ACTIVITY_CREATE_USER: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  ACTIVITY_CREATE_ORGANIZATION: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  ACTIVITY_ASSIGNEE_USER: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  ACTIVITY_ASSIGNEE_ORGANIZATION: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  PERSON_DOB: { property_id: 0, type: 'date', choice_type: 'none' },
  PERSON_SSN: { property_id: 0, type: 'varchar100', choice_type: 'none' },
  PERSON_VETERAN_ID: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  PERSON_MEDICAID_ID: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  PERSON_GENDER: { property_id: 0, type: 'int', choice_type: 'single' },
  PERSON_SEXUAL_ORIENTATION: {
    property_id: 0,
    type: 'int',
    choice_type: 'single'
  },
  PERSON_RACE: { property_id: 0, type: 'int', choice_type: 'multiple' },
  PERSON_ETHNICITY: { property_id: 0, type: 'int', choice_type: 'single' },
  PERSON_MARITAL_STATUS: { property_id: 0, type: 'int', choice_type: 'single' },
  PERSON_PRIMARY_PHONE_NUMBER: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  PERSON_PRIMARY_EMAIL_ADDRESS: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  PERSON_PRIMARY_RESIDENCE_STREET_ADDRESS: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  PERSON_PRIMARY_RESIDENCE_CITY: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  PERSON_PRIMARY_RESIDENCE_STATE: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  PERSON_PRIMARY_RESIDENCE_ZIP: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  PERSON_PRIMARY_RESIDENCE_COUNTY: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  CURRENT_USER: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  CURRENT_USER_ROLE: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  },
  CURRENT_ORGANIZATION: {
    property_id: 0,
    type: 'varchar100',
    choice_type: 'none'
  }
};

Object.keys(standardProperties).forEach((key, i) => {
  standardProperties[key].property_id = i * -1 - 1;
});

export interface ActivityExpressionStandardPropertyValues {
  original_activity_status: string;
  original_activity_start_date: string | null;
  original_activity_end_date: string | null;
  activity_status: string;
  activity_create_date: string;
  activity_start_date: string;
  activity_end_date: string | null;
  activity_create_user: string;
  activity_create_organization: string;
  activity_assignee_user: string | null;
  activity_assignee_organization: string | null;
  person_dob: string | null;
  person_ssn: string | null;
  person_veteran_id: string | null;
  person_medicaid_id: string | null;
  person_gender: number | null;
  person_sexual_orientation: number | null;
  person_race: number | null;
  person_ethnicity: number | null;
  person_marital_status: number | null;
  person_primary_phone_number: string | null;
  person_primary_email_address: string | null;
  primary_residence_street_address: string | null;
  person_primary_residence_city: string | null;
  person_primary_residence_region_abbr: string | null;
  person_primary_residence_postal_code: string | null;
  person_primary_residence_subregion: string | null;
  current_user: string;
  current_user_role: Role;
  current_organization: string;
}

function parseExpressionDate(date: string) {
  if (!date) {
    return new Date(NaN);
  }
  switch (date.length) {
    case 23:
      return new Date(date + 'Z');
    case 10:
      return new Date(date + 'T00:00:00.000Z');
    default:
      return new Date(date);
  }
}

export class ActivityExpressionScope {
  private readonly standardResponses: Record<
    string,
    null | ActivityResponseValue
  >;

  constructor(
    standardPropertyValues: ActivityExpressionStandardPropertyValues,
    private readonly responses: Record<string, null | ActivityResponseValue>,
    private readonly properties: Record<number, Property>,
    private readonly rucaLookup: RucaLookupItem[],
    private readonly areaOfPersistentUnemployment: AreaOfPersistentUnemploymentLookupItem[]
  ) {
    this.standardResponses = {
      [`p${standardProperties.ORIGINAL_ACTIVITY_STATUS.property_id}`]:
        standardPropertyValues.original_activity_status,
      [`p${standardProperties.ORIGINAL_ACTIVITY_START_DATE.property_id}`]:
        standardPropertyValues.original_activity_start_date,
      [`p${standardProperties.ORIGINAL_ACTIVITY_END_DATE.property_id}`]:
        standardPropertyValues.original_activity_end_date,
      [`p${standardProperties.ACTIVITY_STATUS.property_id}`]:
        standardPropertyValues.activity_status,
      [`p${standardProperties.ACTIVITY_CREATE_DATE.property_id}`]:
        standardPropertyValues.activity_create_date,
      [`p${standardProperties.ACTIVITY_START_DATE.property_id}`]:
        standardPropertyValues.activity_start_date,
      [`p${standardProperties.ACTIVITY_END_DATE.property_id}`]:
        standardPropertyValues.activity_end_date,
      [`p${standardProperties.ACTIVITY_CREATE_USER.property_id}`]:
        standardPropertyValues.activity_create_user,
      [`p${standardProperties.ACTIVITY_CREATE_ORGANIZATION.property_id}`]:
        standardPropertyValues.activity_create_organization,
      [`p${standardProperties.ACTIVITY_ASSIGNEE_USER.property_id}`]:
        standardPropertyValues.activity_assignee_user,
      [`p${standardProperties.ACTIVITY_ASSIGNEE_ORGANIZATION.property_id}`]:
        standardPropertyValues.activity_assignee_organization,
      [`p${standardProperties.PERSON_DOB.property_id}`]:
        standardPropertyValues.person_dob,
      [`p${standardProperties.PERSON_SSN.property_id}`]:
        standardPropertyValues.person_ssn,
      [`p${standardProperties.PERSON_VETERAN_ID.property_id}`]:
        standardPropertyValues.person_veteran_id,
      [`p${standardProperties.PERSON_MEDICAID_ID.property_id}`]:
        standardPropertyValues.person_medicaid_id,
      [`p${standardProperties.PERSON_GENDER.property_id}`]:
        standardPropertyValues.person_gender,
      [`p${standardProperties.PERSON_SEXUAL_ORIENTATION.property_id}`]:
        standardPropertyValues.person_sexual_orientation,
      [`p${standardProperties.PERSON_RACE.property_id}`]:
        standardPropertyValues.person_race,
      [`p${standardProperties.PERSON_ETHNICITY.property_id}`]:
        standardPropertyValues.person_ethnicity,
      [`p${standardProperties.PERSON_MARITAL_STATUS.property_id}`]:
        standardPropertyValues.person_marital_status,
      [`p${standardProperties.PERSON_PRIMARY_PHONE_NUMBER.property_id}`]:
        standardPropertyValues.person_primary_phone_number,
      [`p${standardProperties.PERSON_PRIMARY_EMAIL_ADDRESS.property_id}`]:
        standardPropertyValues.person_primary_email_address,
      [`p${standardProperties.PERSON_PRIMARY_RESIDENCE_STREET_ADDRESS.property_id}`]:
        standardPropertyValues.primary_residence_street_address,
      [`p${standardProperties.PERSON_PRIMARY_RESIDENCE_CITY.property_id}`]:
        standardPropertyValues.person_primary_residence_city,
      [`p${standardProperties.PERSON_PRIMARY_RESIDENCE_STATE.property_id}`]:
        standardPropertyValues.person_primary_residence_region_abbr,
      [`p${standardProperties.PERSON_PRIMARY_RESIDENCE_ZIP.property_id}`]:
        standardPropertyValues.person_primary_residence_postal_code,
      [`p${standardProperties.PERSON_PRIMARY_RESIDENCE_COUNTY.property_id}`]:
        standardPropertyValues.person_primary_residence_subregion,
      [`p${standardProperties.CURRENT_USER.property_id}`]:
        standardPropertyValues.current_user,
      [`p${standardProperties.CURRENT_USER_ROLE.property_id}`]:
        standardPropertyValues.current_user_role,
      [`p${standardProperties.CURRENT_ORGANIZATION.property_id}`]:
        standardPropertyValues.current_organization
    };
  }

  SELECTED = (property_id: number, ...codes: number[]) => {
    const response = this.responses[`p${property_id}`];
    if (response === undefined || response === null) {
      return false;
    }
    if (typeof response !== 'number') {
      throw new Error(`Expected numeric response. ${JSON.stringify(response)}`);
    }
    const property = this.properties[property_id];
    if (!property) {
      throw new Error(
        `Expression references ${property_id} but wasn't included in scope.`
      );
    }
    if (property.choice_type === 'multiple') {
      return (codes.reduce((m, shift) => m + 2 ** shift, 0) & response) !== 0;
    } else {
      return codes.some(code => response === code);
    }
  };

  COUNTIF = (...args: boolean[]) => {
    let count = 0;
    for (const arg of args) {
      if (arg) {
        count++;
      }
    }
    return count;
  };

  ANY = (...args: any[]) => {
    return args.some(x => x);
  };

  VALUE = (property_id: number) => {
    const response =
      property_id > 0
        ? this.responses[`p${property_id}`]
        : this.standardResponses[`p${property_id}`];
    if (response !== null && response !== undefined) {
      return response;
    } else {
      return null;
    }
  };

  ANSWERED = (property_id: number) => {
    const response =
      property_id > 0
        ? this.responses[`p${property_id}`]
        : this.standardResponses[`p${property_id}`];
    return response !== null && response !== undefined && response !== '';
  };

  SCORE = (property_id: number, ...choice_ids: number[]) => {
    const response = this.responses[`p${property_id}`];
    if (response === undefined || response === null) {
      return NaN;
    }
    if (typeof response !== 'number') {
      throw new Error(`Expected numeric response. ${JSON.stringify(response)}`);
    }
    const property = this.properties[property_id];
    if (!property) {
      throw new Error(
        `Expression references ${property_id} but wasn't included in scope.`
      );
    }
    if (property.choice_type === 'multiple') {
      return (
        property.choices
          ?.filter(
            c => choice_ids.length === 0 || choice_ids.includes(c.choice_id)
          )
          ?.filter(c => ((2 ** c.choice_id) & response) !== 0)
          .reduce((sum, c) => sum + c.score, 0) ?? NaN
      );
    } else if (property.choice_type === 'single') {
      return (
        property.choices
          ?.filter(
            c => choice_ids.length === 0 || choice_ids.includes(c.choice_id)
          )
          ?.find(c => c.choice_id === response)?.score ?? NaN
      );
    } else {
      return response;
    }
  };

  ROUND = (value: number, digits = 0) => {
    if (typeof value !== 'number') {
      return null;
    }
    return roundNumber(value, digits);
  };

  NOW = () =>
    new Date(
      new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
    ).toISOString();

  AGE = (dob: string, asOf: string) => {
    const parsedDob = parseExpressionDate(dob);
    const parsedAsOf = parseExpressionDate(asOf);
    if (!isFinite(parsedDob.getTime()) || !isFinite(parsedAsOf.getTime())) {
      return null;
    }
    return getAge(parsedDob, parsedAsOf).years;
  };

  DAY = 0;
  MONTH = 1;
  YEAR = 2;
  QUARTER = 3;

  DATEADD = (part: number, value: number, date: string) => {
    const parsed = parseExpressionDate(date);
    if (
      ![this.DAY, this.MONTH, this.QUARTER, this.YEAR].includes(part) ||
      typeof value !== 'number' ||
      !Number.isInteger(value) ||
      !isFinite(parsed.getTime())
    ) {
      return null;
    }
    // https://www.npmjs.com/package/dateadd?activeTab=code
    switch (part) {
      case this.DAY:
        return new Date(
          parsed.setUTCDate(parsed.getUTCDate() + value)
        ).toISOString();
      case this.MONTH:
      case this.QUARTER:
        if (part === this.QUARTER) {
          value = value * 3;
        }
        return new Date(
          parsed.setUTCMonth(parsed.getUTCMonth() + value)
        ).toISOString();
      case this.YEAR:
        return new Date(
          parsed.setUTCFullYear(parsed.getUTCFullYear() + value)
        ).toISOString();
      default:
        throw new Error(`Unexpected date part: ${part}`);
    }
  };

  DATEDIFF = (part: number, start: string, end: string) => {
    const parsedStart = parseExpressionDate(start);
    const parsedEnd = parseExpressionDate(end);
    if (
      ![this.DAY, this.MONTH, this.QUARTER, this.YEAR].includes(part) ||
      !isFinite(parsedStart.getTime()) ||
      !isFinite(parsedEnd.getTime())
    ) {
      return null;
    }
    // https://github.com/melvinsembrano/date-diff/blob/master/src/date-diff.ts
    switch (part) {
      case this.DAY:
        return Math.floor(
          (parsedEnd.getTime() - parsedStart.getTime()) / 86400000
        );
      case this.MONTH:
      case this.QUARTER: {
        let result =
          (parsedEnd.getUTCFullYear() - parsedStart.getUTCFullYear()) * 12 +
          parsedEnd.getUTCMonth() -
          parsedStart.getUTCMonth();
        if (part === this.QUARTER) {
          result = Math.floor(result / 3);
        }
        return result;
      }
      case this.YEAR:
        return parsedEnd.getUTCFullYear() - parsedStart.getUTCFullYear();
      default:
        throw new Error(`Unexpected date part: ${part}`);
    }
  };

  DATEPART = (part: number, date: string) => {
    const parsed = parseExpressionDate(date);
    if (
      ![this.DAY, this.MONTH, this.QUARTER, this.YEAR].includes(part) ||
      !isFinite(parsed.getTime())
    ) {
      return null;
    }
    switch (part) {
      case this.DAY:
        return parsed.getUTCDate();
      case this.MONTH:
        return parsed.getUTCMonth() + 1;
      case this.QUARTER:
        return Math.floor(parsed.getUTCMonth() / 3) + 1;
      case this.YEAR:
        return parsed.getUTCFullYear();
      default:
        throw new Error(`Unexpected date part: ${part}`);
    }
  };

  DATETRUNC = (part: number, date: string) => {
    const parsed = parseExpressionDate(date);
    if (
      ![this.DAY, this.MONTH, this.QUARTER, this.YEAR].includes(part) ||
      !isFinite(parsed.getTime())
    ) {
      return null;
    }
    switch (part) {
      case this.DAY:
        return new Date(
          Date.UTC(
            parsed.getUTCFullYear(),
            parsed.getUTCMonth(),
            parsed.getUTCDate()
          )
        ).toISOString();
      case this.MONTH:
        return new Date(
          Date.UTC(parsed.getUTCFullYear(), parsed.getUTCMonth(), 1)
        ).toISOString();
      case this.QUARTER:
        return new Date(
          Date.UTC(
            parsed.getUTCFullYear(),
            Math.floor(parsed.getUTCMonth() / 3) * 3,
            1
          )
        ).toISOString();
      case this.YEAR:
        return new Date(Date.UTC(parsed.getUTCFullYear(), 0, 1)).toISOString();
      default:
        throw new Error(`Unexpected date part: ${part}`);
    }
  };

  EOMONTH = (date: string) => {
    const parsed = parseExpressionDate(date);
    if (!isFinite(parsed.getTime())) {
      return null;
    }
    const endOfMonth = new Date(
      Date.UTC(parsed.getUTCFullYear(), parsed.getUTCMonth() + 1, 0)
    );
    return endOfMonth.toISOString();
  };

  DATE = (value: string) => {
    const parsed = parseExpressionDate(value);
    if (!isFinite(parsed.getTime())) {
      return null;
    }
    return parsed.toISOString();
  };

  IF = (condition: boolean, trueValue: unknown, falseValue: unknown) => {
    return condition ? trueValue : falseValue;
  };

  LOWER = (s: string) => {
    return s?.toLowerCase() ?? null;
  };

  UPPER = (s: string) => {
    return s?.toUpperCase() ?? null;
  };

  RURAL = (date: string) => {
    const parsed = parseExpressionDate(date);
    if (!isFinite(parsed.getTime())) {
      return null;
    }
    return (
      (this.rucaLookup.find(
        x => parseExpressionDate(x.start_date).getTime() <= parsed.getTime()
      )?.primary_code ?? 0) >= 4
    );
  };

  AREA_OF_PERSISTENT_UNEMPLOYMENT = (date: string) => {
    const parsed = parseExpressionDate(date);
    if (!isFinite(parsed.getTime())) {
      return null;
    }
    return (
      this.areaOfPersistentUnemployment.find(
        x => parseExpressionDate(x.start_date).getTime() <= parsed.getTime()
      )?.persistent_unemployment ?? false
    );
  };

  // SWITCH = (value: unknown, ...cases: unknown[]) => {
  //   const elseCase = cases.length % 2 === 1 ? cases.pop() : null;
  //   for (let i = 0; i < cases.length; i += 2) {
  //     if (value === cases[i]) {
  //       return cases[i + 1];
  //     }
  //   }
  //   return elseCase;
  // };
}

// note: this is legacy- with the new expression validation, it may not be necessary to coerce values as is done here.
export function evaluateActivityExpression(
  propertyType: PropertyType,
  { type, ast }: Expression,
  scope: ActivityExpressionScope
): ActivityResponseValue | null {
  const result = legacyEvaluate(ast, scope);
  if (result === null) {
    return null;
  }
  switch (type) {
    case 'boolean':
      // int used because there's no boolean/bit response table.
      return Boolean(result) ? 1 : 0;
    case 'int': {
      const value = Math.floor(Number(result));
      if (typeof value === 'number' && isNaN(value)) {
        return null;
      }
      return value;
    }
    // @ts-ignore
    case 'currency': // todo: Fix currency type expressions in db, if any. Should be decimal.
    case 'decimal': {
      const value = Number(result);
      if (typeof value === 'number' && isNaN(value)) {
        return null;
      }
      return propertyType === 'currency' ? roundNumber(value, 2) : value;
    }
    case 'string':
      return String(result);
    case 'date':
    case 'datetime': {
      const value = parseExpressionDate(result);
      if (!isFinite(value.getTime())) {
        return null;
      }
      return value.toISOString();
    }
    default:
      throw new Error(`unsupported expression type "${type}"`);
  }
}
