import { avatar, AvatarEntity, AvatarSize } from '../avatar';
import { BasicUserOrganization } from '../model/organization';
import { html } from '../template';

export function userLinkTemplate(
  user: BasicUserOrganization | undefined,
  {
    avatar: renderAvatar = true,
    organization = false,
    text = true,
    popoverDirection = 'right',
    size = 20,
    project_id = null,
    activity_id = null,
    muted = false
  }: {
    avatar?: boolean;
    organization?: boolean;
    text?: boolean;
    popoverDirection?: 'left' | 'right';
    size?: AvatarSize;
    project_id?: string | null;
    activity_id?: string | null;
    muted?: boolean;
  } = {}
) {
  if (!user) {
    return html`<span class="text-italic">Deleted user</span>`;
  }
  const auser: AvatarEntity = {
    type: 'user',
    id: user.user_id,
    login: user.user_login,
    avatar_id: user.user_avatar_id,
    status: user.is_active
      ? user.is_ooo
        ? 'out-of-office'
        : 'none'
      : 'inactive'
  };
  const label =
    user.user_login + (organization ? ` (${user.organization_login})` : '');
  const params = new URLSearchParams();
  if (project_id) {
    params.set('project_id', project_id);
  }
  if (activity_id) {
    params.set('activity_id', activity_id);
  }
  return html`<details
    class="details-reset details-overlay position-relative d-inline-flex color-fg-default"
  >
    <summary
      class="text-bold${muted ? ' color-fg-muted' : ''}"
      aria-label="${label}"
    >
      ${renderAvatar ? avatar(auser, size) : null}
      <span class="Truncate${text ? '' : ' sr-only'}">
        <span class="Truncate-text" style="max-width: 120px;"
          >${user.user_login}</span
        >${organization
          ? html`<span class="Truncate-text" style="max-width: 120px;"
                >(${user.organization_login}</span
              >)`
          : ''}
      </span>
    </summary>
    <details-menu
      src="/api/organizations/${user.organization_id}/members/${user.user_id}?${params}"
      preload
      class="Popover lh-default ${popoverDirection === 'left'
        ? 'right-sm-0'
        : 'left-sm-0'}${text
        ? ''
        : popoverDirection === 'left'
          ? ' mr-sm-n2'
          : ' ml-sm-n2'}"
      style="top: 100%"
    >
      <div
        class="Popover-message Popover-message--large Popover-message--${popoverDirection ===
        'left'
          ? 'top-right'
          : 'top-left'} p-3 mt-sm-2 Box color-shadow-large d-flex gap-2"
      >
        <include-fragment>
          <div class="py-3 d-flex flex-justify-center">
            <div class="spinner-border f1 color-fg-muted"></div>
          </div>
        </include-fragment>
      </div>
    </details-menu>
  </details>`;
}
