import { attr, html } from '@cumu/shared';

export function logoMark({
  size,
  classes,
  bimi = false,
  id
}: { size?: number; classes?: string; bimi?: boolean; id?: string } = {}) {
  return html`${bimi
      ? html`<?xml version="1.0" encoding="UTF-8" standalone="yes" ?>`
      : null}<svg
      xmlns="http://www.w3.org/2000/svg"
      ${attr('version', bimi ? '1.2' : null)}
      ${attr('baseProfile', bimi ? 'tiny-ps' : null)}
      ${attr('width', size?.toString())}
      ${attr('height', size?.toString())}
      ${attr('aria-hidden', bimi ? null : 'true')}
      ${attr('class', classes)}
      ${attr('id', id)}
      viewBox="0 0 22.3 22.3"
    >
      ${bimi ? html`<title>Cumulus</title>` : null}
      <path
        d="m19.95687607 3.4897178-4.15 3.92c-.03-.03-.06-.07-.1-.11a5.407 5.407 0 0 0-1.8-1.2 5.583 5.583 0 0 0-4.1000005 0c-.01.01-.01.01-.02.01l-2.16-5.27999998c.01 0 .02-.01.03-.01a11.167 11.167 0 0 1 8.4000005 0 11.228 11.228 0 0 1 3.69 2.44999998c.07.07.14.14.21.22z"
        fill="#033d8b"
      />
      <path
        d="M9.78687557 6.1097178a5.453 5.453 0 0 0-1.78 1.19 5.413 5.413 0 0 0-1.60001 3.8600002h-5.70999a11.297 11.297 0 0 1 .81999-4.2000002 11.202 11.202 0 0 1 6.11001-6.12999998Z"
        fill="#0969da"
      />
      <path
        d="m9.78687557 16.199718-2.16 5.29a11.394 11.394 0 0 1-3.66001-2.44 11.126 11.126 0 0 1-2.45-3.69 11.298 11.298 0 0 1-.81999-4.2h5.70999a5.348 5.348 0 0 0 .4 2.05 5.506 5.506 0 0 0 2.98001 2.99z"
        fill="#54aeff"
      />
      <path
        d="m19.95487607 18.830718-4.145-3.922c-.03.032-.062.067-.102.104a5.435 5.435 0 0 1-1.8 1.199 5.5 5.5 0 0 1-4.1040005 0l-.015-.008-2.16 5.282.03.012a11.147 11.147 0 0 0 12.0860005-2.45l.002-.003c.067-.067.136-.137.208-.214z"
        fill="#b6e3ff"
      />
    </svg>`;
}
