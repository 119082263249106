import { arrowLeftCircleIcon, icon, xCircleFillIcon12 } from './icons';
import { identicon } from './identicon';
import { logoMark } from './logo-mark';
import {
  anonymousUserId,
  cumulusSystemOrganizationId,
  cumulusSystemUserId
} from './model/user';
import { TemplateResult, attr, html } from './template';

export type AvatarSize =
  | 16
  | 20
  | 24
  | 32
  | 40
  | 48
  | 64
  | 80
  | 128
  | 160
  | 256
  | 320
  | 512
  | 640
  | 1024;

export const avatarConfig = {
  sizes: [
    16, 20, 24, 32, 40, 48, 64, 80, 128, 160, 256, 320, 512, 640, 1024
  ] as AvatarSize[],
  maxSize: 2048,
  type: 'image/jpeg'
};

export function hiResSize(size: AvatarSize): AvatarSize {
  if (size >= 640) {
    return size;
  }
  return (size * 2) as AvatarSize;
}

export type AvatarStatus = 'none' | 'inactive' | 'out-of-office';

export interface AvatarEntity {
  type: 'user' | 'organization';
  id: string;
  login: string;
  avatar_id: string | null;
  status: AvatarStatus;
}

export function avatar(
  entity: AvatarEntity | undefined | null,
  size: AvatarSize,
  classes: string | null = null,
  fluid = false
): TemplateResult {
  const id = null;
  let content: TemplateResult;
  if (!entity || entity.id === anonymousUserId) {
    content = anonymousAvatar(size, 'Avatar-image', id, fluid);
  } else if (entity.id === cumulusSystemUserId) {
    content = cumulusBotAvatar(size, 'Avatar-image', id, fluid);
  } else if (entity.id === cumulusSystemOrganizationId) {
    content = logoMark({ classes: '' });
  } else if (entity.avatar_id) {
    content = html`<img
      class="Avatar-image"
      ${attr('id', id)}
      src="${avatarHref(entity.avatar_id, hiResSize(size))}"
      alt="${entity.login}"
      ${attr('width', fluid ? null : size.toFixed())}
      ${attr('height', fluid ? null : size.toFixed())}
      loading="lazy"
    />`;
  } else {
    content = identicon(entity, size, 'Avatar-image', id, fluid);
  }
  const status: AvatarStatus =
    entity?.id === anonymousUserId || entity?.id === cumulusSystemUserId
      ? 'none'
      : (entity?.status ?? 'none');
  let statusIcon: TemplateResult | null;
  const statusSize =
    size === 20 ? '45%' : size <= 48 ? '40%' : size <= 64 ? '30%' : '25%';
  switch (status) {
    case 'inactive':
      statusIcon = icon(xCircleFillIcon12, {
        classes: 'Avatar-status color-fg-danger',
        size: statusSize,
        viewBox: 12,
        octicon: false
      });
      break;
    case 'out-of-office':
      statusIcon = icon(arrowLeftCircleIcon, {
        classes: 'Avatar-status color-fg-ooo',
        size: statusSize,
        octicon: false
      });
      break;
    default:
      statusIcon = null;
  }

  return html`<span
    class="Avatar ${classes}"
    ${attr('style', fluid ? null : `width: ${size}px; height: ${size}px`)}
    >${content}${statusIcon}</span
  >`;
}

export function avatarHref(avatar_id: string, size: AvatarSize) {
  return `/image-delivery/${avatar_id}/${size}`;
}

function anonymousAvatar(
  size: AvatarSize,
  classes: string | null = null,
  id: string | null = null,
  fluid = false
) {
  return html`<svg
    viewBox="0 0 16 16"
    class="${classes} color-bg-subtle color-fg-muted"
    ${attr('id', id)}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    alt="anonymous"
    ${attr('width', fluid ? null : size.toFixed())}
    ${attr('height', fluid ? null : size.toFixed())}
    style="padding: 8%"
  >
    <path
      fill-rule="evenodd"
      d="M13 14s1 0 1-1-1-4-6-4-6 3-6 4 1 1 1 1h10zm-9.995-.944v-.002.002zM3.022 13h9.956a.274.274 0 0 0 .014-.002l.008-.002c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664a1.05 1.05 0 0 0 .022.004zm9.974.056v-.002.002zM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
    ></path>
  </svg>`;
}

function cumulusBotAvatar(
  size: AvatarSize,
  classes: string | null = null,
  id: string | null = null,
  fluid = false
) {
  return html`<svg
    viewbox="0 0 640 640"
    class="${classes}"
    ${attr('id', id)}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    alt="Cumulus"
    ${attr('width', fluid ? null : size.toFixed())}
    ${attr('height', fluid ? null : size.toFixed())}
  >
    <path fill="none" d="M-1-1h642v642H-1z" />
    <g>
      <path
        fill="#93c7ef"
        d="M367.878 141.818c0-25.707-20.84-46.545-46.545-46.545s-46.545 20.838-46.545 46.545c0 17.225 9.365 32.254 23.273 40.304v83.818h46.545v-83.818c13.908-8.049 23.272-23.079 23.272-40.304z"
      />
      <path
        fill="#5a8bb0"
        d="M321.333 95.273V265.94h23.273v-83.818c13.908-8.049 23.273-23.077 23.273-40.304 0-25.707-20.84-46.545-46.546-46.545z"
      />
      <path
        d="M65.333 312.485h248.242v124.121H65.333V312.485z"
        fill="#93c7ef"
      />
      <path
        d="M329.091 312.485h248.242v124.121H329.091V312.485z"
        fill="#5a8bb0"
      />
      <path
        d="M251.515 436.606h139.636v124.121H251.515V436.606z"
        fill="#93c7ef"
      />
      <path
        d="M321.333 436.606h69.818v124.121h-69.818V436.606z"
        fill="#5a8bb0"
      />
      <path
        d="M111.878 234.909h418.91v279.273h-418.91V234.909z"
        fill="#cce9f9"
      />
      <path
        d="M321.333 234.909h209.455v279.273H321.333V234.909z"
        fill="#93c7ef"
      />
      <path
        fill="#3c5d76"
        d="M251.515 421.091c0 38.558 31.258 69.818 69.818 69.818 38.558 0 69.818-31.26 69.818-69.818H251.515z"
      />
      <path
        fill="#1e2e3b"
        d="M321.333 421.091v69.818c38.558 0 69.818-31.26 69.818-69.818h-69.818z"
      />
      <g stroke="null">
        <path
          fill="#1e73b2"
          d="M416.751 305.214a26.073 26.073 0 00-8.51 5.69 25.882 25.882 0 00-7.651 18.457h-27.302a54.016 54.016 0 013.92-20.083 53.562 53.562 0 0129.215-29.31l10.328 25.246z"
        />
        <path
          fill="#1d479b"
          d="M465.379 292.687l-19.843 18.743c-.144-.143-.287-.335-.479-.526a25.853 25.853 0 00-8.606-5.738 26.695 26.695 0 00-19.604 0c-.048.048-.048.048-.096.048l-10.328-25.246c.048 0 .096-.048.144-.048a53.394 53.394 0 0140.164 0 53.686 53.686 0 0117.643 11.715c.335.335.67.67 1.005 1.052z"
        />
        <path
          fill="#3ba1c9"
          d="M416.751 353.459l-10.328 25.294a54.48 54.48 0 01-17.5-11.667 53.198 53.198 0 01-11.714-17.643 54.02 54.02 0 01-3.921-20.082h27.302a25.571 25.571 0 001.913 9.802 26.327 26.327 0 0014.248 14.296z"
        />
        <path
          fill="#bcdae8"
          d="M465.369 366.039l-19.82-18.753c-.143.153-.296.32-.487.497a25.987 25.987 0 01-8.606 5.733 26.298 26.298 0 01-19.623 0l-.072-.038-10.328 25.256.143.057a53.299 53.299 0 0057.789-11.714l.01-.015c.32-.32.65-.655.994-1.023z"
        />
      </g>
      <g stroke="null">
        <path
          fill="#1e73b2"
          d="M212.751 305.214a26.073 26.073 0 00-8.51 5.69 25.882 25.882 0 00-7.651 18.457h-27.302a54.016 54.016 0 013.92-20.083 53.562 53.562 0 0129.215-29.31l10.328 25.246z"
        />
        <path
          fill="#1d479b"
          d="M261.379 292.687l-19.843 18.743c-.144-.143-.287-.335-.479-.526a25.853 25.853 0 00-8.606-5.738 26.695 26.695 0 00-19.604 0c-.048.048-.048.048-.096.048l-10.328-25.246c.048 0 .096-.048.144-.048a53.394 53.394 0 0140.164 0 53.686 53.686 0 0117.643 11.715c.335.335.67.67 1.005 1.052z"
        />
        <path
          fill="#3ba1c9"
          d="M212.751 353.459l-10.328 25.294a54.48 54.48 0 01-17.5-11.667 53.198 53.198 0 01-11.714-17.643 54.02 54.02 0 01-3.921-20.082h27.302a25.571 25.571 0 001.913 9.802 26.327 26.327 0 0014.248 14.296z"
        />
        <path
          fill="#bcdae8"
          d="M261.369 366.039l-19.82-18.753c-.143.153-.296.32-.487.497a25.987 25.987 0 01-8.606 5.733 26.298 26.298 0 01-19.623 0l-.072-.038-10.328 25.256.143.057a53.299 53.299 0 0057.789-11.714l.01-.015c.32-.32.65-.655.994-1.023z"
        />
      </g>
    </g>
  </svg>`;
}
