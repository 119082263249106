import { target } from '@github/catalyst';
import { Attachment } from '@github/file-attachment-element';
import { readExcelFile } from '../excel';
import { clearValidationErrors } from '../forms/validation';
import { controller } from './controller';
import { dispatchChange } from './util';

@controller('excel-attachment')
class ExcelAttachmentElement extends HTMLElement {
  @target declare fileInput: HTMLInputElement;
  @target declare valueInput: HTMLInputElement;
  @target declare filenameInput: HTMLInputElement;

  async attachmentAccepted(event: CustomEvent<{ attachments: Attachment[] }>) {
    const attachments = event.detail.attachments;
    clearValidationErrors(this.valueInput.form!);
    try {
      this.fileInput.disabled = true;
      this.fileInput.labels?.[0].classList.add('btn-busy');
      const file = attachments[0].file;
      const data = await readExcelFile(
        file.arrayBuffer(),
        file.name,
        this.valueInput.form!
      );
      this.valueInput.value = JSON.stringify(data);
      this.filenameInput.value = file.name;
      await this.querySelector('form-behavior')?.submit();
    } finally {
      this.fileInput.disabled = false;
      this.fileInput.labels?.[0].classList.remove('btn-busy');
      this.fileInput.focus();
      dispatchChange(this.valueInput);
    }
  }

  valueInputFocused() {
    this.fileInput.focus();
  }
}

declare global {
  interface Window {
    ExcelAttachmentElement: typeof ExcelAttachmentElement;
  }

  interface HTMLElementTagNameMap {
    'excel-attachment': ExcelAttachmentElement;
  }
}
